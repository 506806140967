import axios from 'axios';
import * as Sentry from '@sentry/nextjs';

import { XSRF_TOKEN_COOKIE_NAME } from './consts';
import { customSignOut } from '@/utils/customSignOut';

const cloudApiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  withCredentials: true,
});

cloudApiClient.interceptors.request.use(
  (config) => {
    const cookieValue = decodeURIComponent(
      document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${XSRF_TOKEN_COOKIE_NAME}=`))
        ?.split('=')[1] || ''
    );
    if (cookieValue) {
      config.headers['X-XSRF-TOKEN'] = cookieValue;
    }
    return config;
  },
  (error) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

cloudApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 419) {
      error.message = 'Your session has expired. Please sign in again.';
      Sentry.captureException(error);
      customSignOut();
      return Promise.reject(error);
    }
    if (error.response && error.response.data) {
      const data = error.response.data;

      if (typeof data === 'string') {
        error.message = data;
      } else if (data.message) {
        error.message = data.message;
      } else if (data.error) {
        error.message = data.error;
      }
    }

    if (error.message === 'Network Error') {
      error.message =
        'Cloud API call failed. Check your internet connection. If the issue persists, please contact the customer service.';
    }

    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export { cloudApiClient };
