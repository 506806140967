import { getSearchQuery } from '@/utils/getSearchQuery';
import { PublishedPageData } from '../interfaces/api';
import { getImpersonation } from '../../../utils/getImpersonation';
import { appendToQueryString } from '@/utils/queryString';
import { cloudApiClient } from '@/services/clientSideCloudApiService';

export const getPublishedPage = async ({
  query,
  page,
  rapidId,
  showOneClick,
}: {
  query?: string;
  page?: number;
  rapidId?: number;
  showOneClick?: boolean;
}) => {
  const { isImpersonating, email } = await getImpersonation();
  if (isImpersonating) {
    const { data: published } = await cloudApiClient.post<PublishedPageData>(`embeds`, {
      query,
      page,
      impersonatedEmail: email,
    });

    return published;
  }
  const params = appendToQueryString(getSearchQuery(query, page), {
    showOneClick: showOneClick,
    rapidId: rapidId ? rapidId : undefined,
  });
  const { data: published } = await cloudApiClient.get<PublishedPageData>(`embeds${params}`);

  return published;
};
